import React from "react"

const Overlay = () => {
  return (
    <div className="intro-overlay">
      <div className="front-1"></div>
      {/* <div className="middle-1"></div> */}
      <div className="back-1"></div>
   
    </div>
  )
}

export default Overlay
