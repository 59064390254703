import React, {  useEffect } from "react"

//components
import HomeBanner from "../components/Home/HomeBanner"
import Overlay from "../components/Overlays/Overlay"

import {gsap} from 'gsap'
//css
import "../styles/homeStyles.scss"
import "../styles/overlay.scss"



const IndexPage = () => {




   useEffect(() => {
    //timeline
    const tl = gsap.timeline()
    tl.to(".front-1", {
      height: 0,
      ease: "Power2.easeOut",
      stagger: 0.2,
      delay: .3,
      duration:  1.3,
    })
      .to(".back-1", {
        duration: 0.8,
        height: 0,
        ease: "Power3.easeOut",
        stagger: 0.2,
        delay: -0.7,
      })
       /* .to('.middle-1',{
      height: 0,
      ease: "Power2.easeOut",
      stagger: 0.2,
      delay:  -.4,
      duration: .4,
      })  */
      .to(".intro-overlay", { css: { display: "none" }})
      
      .from(['.title-wrapper h1', '.title-wrapper p'], {
        duration:1.4,
        opacity:0,
        y:40,
        delay:-0.6,
        ease: 'Power3.easeOut',
        stagger:{
          amount: 0.5
        }
      })
      .from('.clone', {css:{display:'none'}, delay: .3})
    }, [])

  
  


  return (
    <>
      
      <Overlay />
      <HomeBanner/> 
    
  
    </>
  )
}

export default IndexPage
