import React, {useRef, useEffect, useState} from "react"

import "../../styles/homeStyles.scss";
import {gsap} from 'gsap'
import {Work, RightArrow , AboutMe, Contact} from '../../assets/text'
import {Link, navigate} from 'gatsby'
//context
import {
  useGlobalDispatchContext,
} from '../../context/globalContext'




const HomeBanner = () => {

  

  //Refs
  //List
  const homeNavigationList = useRef(null)
  //left side
  const line1 = useRef(null)
  const line2 = useRef(null)
  const line3 = useRef(null)
  const line4 = useRef(null)
  const line5 = useRef(null)
  const line6 = useRef(null)
  const line7 = useRef(null)
  const list1 = useRef(null)
  const list2 = useRef(null)
  const list3 = useRef(null)
  const list4 = useRef(null)
  //right side
  const item1 = useRef(null)
  const item2 = useRef(null)
  const item3 = useRef(null)
  const number1 = useRef(null)
  const number2 = useRef(null)
  const number3 = useRef(null)

  const tl = gsap.timeline()

  const dispatch = useGlobalDispatchContext()

  
  const onCursor = cursorType => {
    dispatch({ type: "CURSOR_TYPE", cursorType: cursorType })
  }

//Intro animation
useEffect(()=> {
  //reset cursor
  onCursor()

  tl.from([line1.current, line2.current ], {
    duration:.7,
    delay:1.4,
    y:40,
    opacity:0,
    skewY: 10,
    ease: 'Power3.Out',
    stagger:{
      amount: 0.4
    }
  })
  .from([line3.current, line4.current, line5.current, line6.current, line7.current], {
    duration:.8,
    delay:-.7,
    y:30,
    opacity:0,
    skewY: 10,
    ease: 'Power3.Out',
    stagger:{
      amount:.4
    }
  })
  .from([list1.current, list2.current, list3.current, list4.current], {
    duration:1,
    y: 20,
    opacity:0,
    delay: -.3,
    ease: 'Power3.easeOut',
    stagger:{
      amount: .3
    }
  }) 
  .from([item1.current, item2.current, item3.current], {
    duration:0.9,
    delay:-1.5,
    opacity:0,
    skewY:12,
    y:300,
    x:-80,
    ease:'Power3.inOut',
    rotate: 20,
    stagger:{
      amount:.5
    }
  })
  .from([number1.current, number2.current, number3.current], {
    opacity:0,
    duration:.5,
    delay:0,
    stagger:{
      amount:.4
    }
  })
  .to('.home-navigation-list', {css:{pointerEvents:'all'}, duration:0, delay:-2})
})

//Exit animation (contact)
  const handleContactClick = (e) => {
    const tl = gsap.timeline()
    e.preventDefault() 
      tl.to('.home-navigation-arrow',{
        duration: .4,
        opacity:0
      })
      .to([line1.current, line2.current, line3.current, line4.current, line5.current, line6.current, line7.current,list1.current, list2.current, list3.current, list4.current],{
        duration:1,
        y: -30,
        skewX:10,
        opacity:0,
        ease:'Power3.easeOut',
        stagger:{
         amount: .5
        }
      })
      .to([list1.current, list2.current, list3.current, list4.current],{
        duration:.6,
        y:-30,
        opacity:0,
        delay:-.7,
        stagger:{
          amount: .3
        }
      })
      .to([item3.current, item2.current, item1.current], {
        duration:1,
        delay:-1.5,
        opacity:0,
        skewY:12,
        y:300,
        x:-80,
        ease:'Power3.inOut',
        rotate: 20,
        stagger:{
          amount:.5
      }
      })     
    setTimeout(() => { 
      navigate('/contact');
    }, 2200);
  }
//Exit animation (work)
const handleWorkClick = (e) => {
  const tl = gsap.timeline()
  e.preventDefault() 
    tl.to('.home-navigation-arrow',{
      duration: .4,
      opacity:0
    })
    .to([line1.current, line2.current, line3.current, line4.current, line5.current, line6.current, line7.current,list1.current, list2.current, list3.current, list4.current],{
      duration:1,
      y: -30,
      skewX:10,
      opacity:0,
      ease:'Power3.easeOut',
      stagger:{
       amount: .5
      }
    })
    .to([list1.current, list2.current, list3.current, list4.current],{
      duration:.6,
      y:-30,
      opacity:0,
      delay:-.7,
      stagger:{
        amount: .3
      }
    })
    .to([item3.current, item2.current, item1.current], {
      duration:.7,
      delay:-1.5,
      opacity:0,
      skewY:12,
      y:300,
      x:-80,
      ease:'Power3.inOut',
      rotate: 20,
      stagger:{
        amount:.5
    }
    })     

  setTimeout(() => {
    navigate('/work');
  }, 2300);
}
//Exit animation (about)
const handleAboutClick = (e) => {
  const tl = gsap.timeline()
  e.preventDefault() 
    tl.to('.home-navigation-arrow',{
      duration: .4,
      opacity:0
    })
    .to([line1.current, line2.current, line3.current, line4.current, line5.current, line6.current, line7.current,list1.current, list2.current, list3.current, list4.current],{
      duration:1,
      y: -30,
      skewX:10,
      opacity:0,
      ease:'Power3.easeOut',
      stagger:{
       amount: .5
      }
    })
    .to([list1.current, list2.current, list3.current, list4.current],{
      duration:.6,
      y:-30,
      opacity:0,
      delay:-.7,
      stagger:{
        amount: .3
      }
    })
    .to([item3.current, item2.current, item1.current], {
      duration:.7,
      delay:-1.5,
      opacity:0,
      skewY:12,
      y:300,
      x:-80,
      ease:'Power3.inOut',
      rotate: 20,
      stagger:{
        amount:.5
    }
    })     

  setTimeout(() => {
    navigate('/about');
  }, 2300);
}





  return (
    <div className="home-banner-container">
    <div className="home-banner-wrapper">
    <div className="banner-left">
        <div className="home-content">
          <div className="home-title">
            <h1>
              <div ref={line1} className='title-container'>
                <span>Hello </span>
              </div>
              <br/>
              <div ref={line2}  className='title-container'>
                <span >there</span>
              </div>
              
            </h1>
          </div>
          <div className='banner-about'>
            <div className='text-line'  ref={line3}> 
            <p className="line">My name is Nikos Tzamalis,</p>
            </div>
          
            <br/>
            <div className='text-line' ref={line4}>
            <p  className="line">I am Front End Developer / UI UX Designer</p>
            </div>
           
            <br/>
            <div className='text-line' ref={line5}>
            <p  className="line">from Athens, Greece</p>
            </div>
            
            <br/>
            <div className='text-line' ref={line6}>
            <p  className="line">Nice to meet you!</p>
            </div>
            
          </div>
          <div className="home-social">
            <p ref={line7}>Hang out with me @</p>
            <ul className='home-social-list'>
                <li className="home__social__item" ref={list1} >
                  <a  className="home__social__link" target="_blank" href='/' 
                    onMouseEnter={() => onCursor("pointer")}
                    onMouseLeave={()=> onCursor()}>facebook</a>
                </li>
                <li className="home__social__item" ref={list2}>
                  <a   className="home__social__link" rel="noopener" href='/' target="_blank"
                      onMouseEnter={() => onCursor("pointer")}
                      onMouseLeave={()=> onCursor()}
                  >linkedin</a></li>
                <li className="home__social__item" ref={list3} >
                  <a  className="home__social__link" rel="noopener" href='/' target="_blank" 
                     onMouseEnter={() => onCursor("pointer")}
                     onMouseLeave={()=> onCursor()}  
                  >twitter</a></li>
                <li className="home__social__item" ref={list4}>
                  <a  className="home__social__link" rel="noopener" href='/' target="_blank"
                      onMouseEnter={() => onCursor("pointer")}
                      onMouseLeave={()=> onCursor()}  
                  >github</a></li>
            </ul>
          </div>
        </div>
      </div>
      <div className="banner-right">
        <div className="home-navigation">
          <ul className='home-navigation-list' ref={homeNavigationList}>
            <li
             onMouseEnter={() => onCursor("pointer")}
             onMouseLeave={()=> onCursor()}  
            >
            
              <div className="home-navigation-arrows">
                <span className='home-navigation-arrow'>    <RightArrow/></span>
                <span className='home-navigation-arrow' >    <RightArrow/></span>
              </div>
              <Link  to='/about' onClick={handleAboutClick}>
             <div className="home-navigation-item" ref={item1}>
             <span className='home-navigation-number' ref={number1}>01</span>
              <div  className='home-navigation-link'  >
                 <AboutMe/> 

              </div>
             </div>
             </Link>
            </li>
       
            <li
             onMouseEnter={() => onCursor("pointer")}
             onMouseLeave={()=> onCursor()}  
            >
             
             <div className="home-navigation-arrows">
               <span className='home-navigation-arrow' >    <RightArrow/></span>
               <span className='home-navigation-arrow' >    <RightArrow/></span>
             </div>
            <Link to='/work' onClick={handleWorkClick} >
            <div  className="home-navigation-item" ref={item2}>
            <span className='home-navigation-number' ref={number2}>02</span>
             <div className='home-navigation-link'  >

              <Work /> 
             </div>
            </div>
           </Link> 
           </li>
           <li
            onMouseEnter={() => onCursor("pointer")}
            onMouseLeave={()=> onCursor()}
           >
             
             
             <div className="home-navigation-arrows">
               <span className='home-navigation-arrow'>
                  <RightArrow/>
               </span>
               <span className='home-navigation-arrow'>    <RightArrow/></span>
             </div>
           <Link to='/contact' onClick={handleContactClick}>
            <div className="home-navigation-item" ref={item3}>
            <span className='home-navigation-number' ref={number3}>03</span>
            <div className='home-navigation-link' >
                 <Contact /> 

             </div>
            </div>
            </Link>
           </li>
          </ul>
        </div>
      </div>
    </div>
    </div>
  )
}

export default HomeBanner
